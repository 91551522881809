import { getReadableImageUrl } from '@/lib/directus'
import { sanitizeHTML } from '@/lib/utils'
import Image from 'next/image'

interface SecWhatCanTreatData {
	heading?: {
		title?: string
		text?: string
	}
	text?: {
		text?: string
	}
	content_collection?: {
		id: string
		site_content_collections_id: {
			collection: {
				item: {
					title: string
					text: string
				}
			}[]
		}
	}[]
	image?: {
		alt: string
		image_file: {
			image: {
				id: string
				filename_disk: string
			}
		}
	}
}

export const fields = [
	'heading.title',
	'heading.text',
	'content_collection.id',
	'content_collection.site_content_collections_id.collection.item:site_content.title',
	'content_collection.site_content_collections_id.collection.item:site_content.text',
	'image.alt',
	'image.image_file.image.id',
	'image.image_file.image.filename_download',
	'text.text',
] as const

interface SecWhatCanTreatProps {
	data: SecWhatCanTreatData
}

export default function SecWhatCanTreat({ data }: SecWhatCanTreatProps) {

	return (
		<section className='SecWhatCanTreat py-16 lg:py-24'>
			<div className='container'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-16'>
					{/* Left Column */}
					<div className='flex flex-col gap-8'>
						{data?.image?.image_file?.image?.id && (
							<div className='relative aspect-[642/400] w-full overflow-hidden bg-neutral lg:order-2'>
								<Image
									src={
										getReadableImageUrl(
											data.image.image_file.image.id,
											data.image.image_file.image.filename_disk || ''
										) || ''
									}
									alt={data?.image?.alt || ''}
									fill
									className='object-cover object-center'
									priority
								/>
							</div>
						)}
						<div className='flex flex-col gap-4 lg:order-1'>
							<h2 className='text-4xl md:text-5xl font-light font-freightBig leading-tight md:leading-[48px]'>
								{data?.heading?.title || ''}
							</h2>
							<div
								className='text-base font-light font-aileron prose'
								dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
							/>
						</div>
					</div>

					{/* Right Column */}
					<div className='flex flex-col gap-8'>
						{data?.content_collection?.map(collection => (
							<div key={collection.id} className='flex flex-col gap-3'>
								<h3 className='text-3xl md:text-4xl font-light font-freightBig leading-tight md:leading-normal text-[#332f2c]'>
									{collection.site_content_collections_id.collection[0]?.item
										?.title || ''}
								</h3>
								<div
									className='text-base font-light font-aileron prose'
									dangerouslySetInnerHTML={sanitizeHTML(
										collection.site_content_collections_id.collection[1]?.item
											?.text || ''
									)}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
