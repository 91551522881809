import { sanitizeHTML } from "@/lib/utils";
import Button from '@/components/ui/button';
import Link from 'next/link';

interface SecTwoColGrayData {
  heading?: {
    title?: string;
  };
  text?: {
    text?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
  content_collection_left?: {
    id: string;
    site_content_collections_id: {
      collection: {
        item: {
          title: string | null;
          text: string;
        };
      }[];
    };
  }[];
  content_collection_right?: {
    id: string;
    site_content_collections_id: {
      collection: {
        item: {
          title: string | null;
          text: string;
        };
      }[];
    };
  }[];
}

export const fields = [
  // heading
  "heading.title",
  
  // text
  "text.text",
  
  // button
  "button.label",
  "button.href",
  
  // content collection left
  "content_collection_left.id",
  "content_collection_left.site_content_collections_id.collection.item:site_content.title",
  "content_collection_left.site_content_collections_id.collection.item:site_content.text",
  
  // content collection right
  "content_collection_right.id",
  "content_collection_right.site_content_collections_id.collection.item:site_content.title",
  "content_collection_right.site_content_collections_id.collection.item:site_content.text",
] as const;

interface SecTwoColGrayProps {
  data: SecTwoColGrayData;
}

export default function SecTwoColGray({ data }: SecTwoColGrayProps) {
  return (
		<section className='SecTwoColGray bg-[#d9d9d9]'>
			<div className='container py-24 md:py-28'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-18'>
					{/* Left Column */}
					<div className='flex flex-col gap-10'>
						<h2
							className='text-4xl lg:text-[50px] font-light font-freightBig leading-[1.1] text-black'
							dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || '')}
						/>

						{data?.content_collection_left?.[0]?.site_content_collections_id?.collection?.map(
							(item, index) => (
								<div
									key={index}
									className='prose text-base font-aileron text-black'
									dangerouslySetInnerHTML={sanitizeHTML(item?.item?.text || '')}
								/>
							)
						)}

						{data?.button?.label && (
							<Button asChild className='w-fit hidden lg:block'>
								<Link href={data?.button?.href || ''}>
									{data?.button?.label}
								</Link>
							</Button>
						)}
					</div>

					{/* Right Column */}
					<div className='rbox flex flex-col gap-5'>
						{data?.content_collection_right?.[0]?.site_content_collections_id?.collection?.map(
							(item, index) => (
								<div
									key={index}
									className='prose prose-li:my-2 prose-li:text-black prose-ul:pl-5 prose-ul:list-disc prose-ul:text-black prose-li:marker:text-black text-base font-aileron text-black'
									dangerouslySetInnerHTML={sanitizeHTML(item?.item?.text || '')}
								/>
							)
						)}
						{data?.button?.label && (
							<Button
								asChild
								className='w-fit hidden lg:hidden block lg:hidden'
							>
								<Link href={data?.button?.href || ''}>
									{data?.button?.label}
								</Link>
							</Button>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}