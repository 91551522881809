
interface SectionAnchorData {
  anchor_id: string
}

export const fields = [
  "anchor_id",
]

interface SectionAnchorProps {
  data: SectionAnchorData
}

export default function SectionAnchor({ data }: SectionAnchorProps) {

  return (
    <div
      id={data.anchor_id.replace('#', '')}
      className="relative"
    />
  )
} 