import Image from 'next/image'
import { sanitizeHTML } from '@/lib/utils'
import { getReadableImageUrl } from '@/lib/directus'
import Button from '@/components/ui/button'
import Link from 'next/link'

interface SecWhatSetsData {
	heading?: {
		title?: string
	}
	text?: {
		text?: string
	}
	testimon?: {
		text?: string
	}
	button?: {
		href?: string
		label?: string
	}
	image?: {
		alt?: string
		image_file?: {
			image?: {
				id?: string
				filename_disk?: string
			}
		}
	}
	content_collection?: {
		id: string
		site_content_collections_id: {
			collection: {
				item: {
					title: string | null
					text: string | null
				}
			}[]
		}
	}[]
}

export const fields = [
	'heading.title',
	'text.text',
	'image.alt',
	'image.image_file.image.id',
	'image.image_file.image.filename_download',
	'content_collection.id',
	'content_collection.site_content_collections_id.collection.item:site_content.title',
	'content_collection.site_content_collections_id.collection.item:site_content.text',
	'testimon.text',
	'button.href',
	'button.label',
] as const

interface SecWhatSetsProps {
	data: SecWhatSetsData
}

export default function SecWhatSets({ data }: SecWhatSetsProps) {
	return (
		<section className='SecWhatSets py-16 md:py-24 SecWhatSets'>
			<div className='container'>
				{/* Top section with image and main content */}
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-start'>
					{/* Left Column - Image */}
					<div className='relative w-full h-[200px] md:h-[369px] overflow-hidden'>
						<Image
							src={getReadableImageUrl(
								data.image.image_file.image.id,
								data.image.image_file.image.filename_download || ''
							)}
							alt={data?.image?.alt || ''}
							fill
							className='object-cover'
							quality={95}
							loading='lazy'
						/>
					</div>

					{/* Right Column - Content */}
					<div className='flex flex-col gap-7'>
						<h2
							className='font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-8 text-left'
							dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || '')}
						/>

						<div
							className='prose text-[#212529] text-base font-light font-aileron leading-[27px] tracking-tight'
							dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
						/>
					</div>
				</div>

				{/* World-Class Surgeon and Quote section */}
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 mt-16'>
					<div className='flex flex-col gap-3.5'>
						<h3
							className='text-4xl md:text-5xl font-light font-freightBig leading-tight md:leading-[1.2] text-[#030303]'
							dangerouslySetInnerHTML={sanitizeHTML(
								data?.content_collection?.[0]?.site_content_collections_id
									?.collection?.[0]?.item?.title || ''
							)}
						/>
						<div
							className='prose text-[#212529] text-sm md:text-base font-light font-aileron leading-[26px] md:leading-[27px] tracking-tight w-full'
							dangerouslySetInnerHTML={sanitizeHTML(
								data?.content_collection?.[0]?.site_content_collections_id
									?.collection?.[1]?.item?.text || ''
							)}
						/>
					</div>

					{data?.testimon?.text ? (
						<div
							className='text-testimonial text-black text-2xl md:text-2xl font-light font-aileron leading-[28px] md:leading-loose tracking-tight italic max-w-[80%] mx-auto text-center text-[18px] md:text-2xl'
							dangerouslySetInnerHTML={sanitizeHTML(data?.testimon?.text || '')}
						/>
					) : (
						<div className='flex flex-col gap-3.5'>
							<h3
								className='text-4xl md:text-5xl font-light font-freightBig leading-tight md:leading-[1.2] text-[#030303]'
								dangerouslySetInnerHTML={sanitizeHTML(
									data?.content_collection?.[1]?.site_content_collections_id
										?.collection?.[0]?.item?.title || ''
								)}
							/>
							<div
								className='prose text-[#212529] text-sm md:text-base font-light font-aileron leading-[26px] md:leading-[27px] tracking-tight w-full'
								dangerouslySetInnerHTML={sanitizeHTML(
									data?.content_collection?.[1]?.site_content_collections_id
										?.collection?.[1]?.item?.text || ''
								)}
							/>
						</div>
					)}
				</div>

				{/* All content sections */}
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 mt-16'>
					{data?.content_collection?.slice(data?.testimon?.text ? 1 : 2).map(item => (
						<div key={item.id} className='flex flex-col gap-7'>
							<h3
								className='text-4xl md:text-5xl font-light font-freightBig leading-tight md:leading-[1.2] text-[#030303]'
								dangerouslySetInnerHTML={sanitizeHTML(
									item?.site_content_collections_id?.collection?.[0]?.item
										?.title || ''
								)}
							/>
							<div
								className='prose text-[#212529] text-base font-light font-aileron leading-[27px] tracking-tight'
								dangerouslySetInnerHTML={sanitizeHTML(
									item?.site_content_collections_id?.collection?.[1]?.item
										?.text || ''
								)}
							/>
						</div>
					))}
				</div>

				{/* Book Consultation Button */}
				<div className='flex justify-center mt-16'>
					<Button asChild>
						<Link href={data?.button?.href || ''}>{data?.button?.label}</Link>
					</Button>
				</div>
			</div>
		</section>
	)
}
