import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface SecWhyChooseData {
	heading?: {
		title?: string
	}
	text?: {
		text?: string
	}
	image?: {
		alt?: string
		image_file?: {
			image?: {
				id?: string
				filename_disk?: string
			}
		}
	}
	video?: {
		embed_url: string
		embed_title: string
	}
	achievements?: {
		site_content_collections_id?: {
			collection?: {
				item?: {
					title?: string
				}
			}[]
		}
	}[]
}

export const fields = [
  "heading.title",
  "text.text",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
  "achievements.site_content_collections_id.collection.item:site_content.title",
	"video.embed_url",
	"video.embed_title",
] as const;

interface SecWhyChooseProps {
  data: SecWhyChooseData;
}

export default function SecWhyChoose({ data }: SecWhyChooseProps) {
  const achievements = [
    "Over 1000 5-star reviews",
    "International lecturer in face and neck lift surgery at ASOPRS/AAO",
    "Author of a book about face lifts"
  ];

  return (
		<section className='SecWhyChoose pt-20'>
			<div className='container'>
				{/* Main content grid */}
				<div className='grid lg:grid-cols-2 gap-20 mb-8'>
					{/* Image - Shown first on mobile */}
					<div className='relative overflow-hidden h-[200px] md:h-[481px] lg:order-2'>
						{data?.image?.image_file?.image?.id && (
							<Image
								src={getReadableImageUrl(
									data.image.image_file.image.id,
									data.image.image_file.image.filename_download || ''
								)}
								alt={data?.image?.alt || ''}
								fill
								className='object-cover'
								quality={95}
								loading='lazy'
							/>
						)}
						{data?.video && (
							<div className='relative w-full aspect-w-16 aspect-h-9 rounded-[18px] overflow-hidden'>
								<iframe
									src={data.video.embed_url}
									title={data.video.embed_title}
									loading={'lazy'}
									className='w-full h-full'
									allowFullScreen
								/>
							</div>
						)}
					</div>

					{/* Text content */}
					<div className='flex flex-col gap-8 lg:order-1'>
						<h2 className='text-4xl mb-8 md:text-5xl font-light font-freightBig leading-tight md:leading-[50px] text-[#030303]'>
							{data?.heading?.title || ''}
						</h2>
						<div
							className='prose text-base font-light font-aileron leading-7 tracking-tight text-[#212529]'
							dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
						/>
					</div>
				</div>

				{/* Bottom achievements section */}
				<div className='flex flex-col gap-6'>
					<div className='w-full h-px bg-black' />

					<div className='grid md:grid-cols-3 gap-8 text-center items-center'>
						{achievements.map((achievement, index) => (
							<div
								key={index}
								className='text-2xl font-light font-freightBig leading-[32px] text-[#332f2c]'
							>
								{achievement}
							</div>
						))}
					</div>

					<div className='w-full h-px bg-black' />
				</div>
			</div>
		</section>
	)
}